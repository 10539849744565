import React from "react";
import AgoraVideoPlayer from "./components/player";

export default function GridLayout({
  remoteUsers,
  audioTrack,
  videoTrack,
  size,
  videoOff,
  muted,
  mutedInfo,
  speakingUser
}) {
  const showRemoteUserInfo = (info) => {
    const data = info.split("|");
    return data[0];
  };

  return (
    <>
      <div className={`video-call-wrapper vx-${size}`}>
        <div className="video-participant">
          <div className="participant-action">
            <button className={`${muted ? "btn-mute" : "btn-unmute"}`}></button>
            <button
              className={`${videoOff ? "btn-video-off" : "btn-video-on"}`}
            ></button>
          </div>
          <a href="#" className="name-tag">
            {"You"}
          </a>
          <AgoraVideoPlayer videoTrack={videoTrack} />
        </div>
        {Object.keys(remoteUsers).length ? (
          <>
            {Object.keys(remoteUsers).map((id) => (
              <div className={`video-participant ${speakingUser===id?'speaking':''}`}>
                <div className="participant-action">
                  {mutedInfo[id]?.audio ? (
                    <button className="btn-mute"></button>
                  ) : (
                    <button className="btn-unmute"></button>
                  )}
                  {mutedInfo[id]?.video ? (
                    <button className="btn-video-off"></button>
                  ) : (
                    <button className="btn-video-on"></button>
                  )}
                </div>
                <a href="#" className="name-tag">
                  {showRemoteUserInfo(remoteUsers[id]?.uid)}
                </a>
                <AgoraVideoPlayer
                  videoTrack={remoteUsers[id]?.videoTrack}
                  audioTrack={remoteUsers[id]?.audioTrack}
                  key={id}
                />
              </div>
            ))}
          </>
        ) : null}
      </div>
    </>
  );
}
