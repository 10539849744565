import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import JoinRoom from "./joinRoom";  // Import JoinRoom component
import Zoomcall from "./Zoom/zoomcall";

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        {/* <Route path="/" element={<Home />} /> */}
        <Route path="/" element={<JoinRoom />} />
        <Route path="/call/:channelName/:userid" element={<Zoomcall />} />
         {/* Join Room Route */}
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
