import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import './joinroom.css'

const JoinRoom = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [meetingId, setMeetingId] = useState();
  const [name, setName] = useState();
  const [isFormValid, setIsFormValid] = useState(false);

  const joinRoom = (e) => {
    e.preventDefault();
    if (isFormValid) {
      navigate(`/call/${meetingId}/${name}`);
    }
  };

  useEffect(() => {
    setIsFormValid(meetingId && name);
  }, [meetingId, name]);

  return (
    <div className="meting_box">
      <div className="meeting_sub_box">
        <h1 style={{fontSize:20, marginBottom:10}}>Join Meeting</h1>
        <form>
          <div>
            <div className="titlelabele"> Meeting Id</div>
            <input className="formcontroller" type="text" onChange={(e) => setMeetingId(e.target.value)} placeholder="Enter your id " required />
          </div>
          <div>
            <div className="titlelabele">Name</div>
            <input className="formcontroller" type="text" onChange={(e) => setName(e.target.value)} placeholder="Enter your name " required/>
          </div>
          <div>
            <button 
              className={`meeting-join ${!isFormValid ? 'disabled' : ''}`} 
              onClick={joinRoom} 
              disabled={!isFormValid}
            >
              Join Meeting
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default JoinRoom;